import React from 'react';
import { withPrefix } from 'gatsby';
import PropTypes from 'prop-types';

import '../pages-content-layout.scss';
import necessaryCookies from '../../../images/necessaryCookies.png';
import statisticCookies from '../../../images/statisticCookies.png';
import socialCookies from '../../../images/socialCookies.png';

function PrivacyContentLayout() {
  return (
    <section className="pages-content-layout-section" id="pages-content-layout">
      <div className="container pages-content-layout" id="pages-content-layout">

        <h3 className="pages-content-layout__subtitle">ABOUT US</h3>

        <p className="pages-content-layout__para">
          We, The Claims Guys Limited, are committed to safeguarding the privacy of our
          website visitors, our prospective customers (“Prospects”) and our Customers;
          our Privacy Policy sets out how we will treat your personal information.
        </p>
        <p className="pages-content-layout__para">
          We are a Data Controller, which means that we determine the purposes and
          the means of processing your personal information. We are registered directly
          with the Information Commissioner’s Office (ICO) and our data protection
          registration number is Z1652832. Our company is registered in England and Wales.
        </p>

        <div className="pages-content-layout__cta-link-wrapper">
          <a
            className="pages-content-layout__cta-link"
            href={withPrefix('/TCG_Privacy-Policy_POL_014_01_22.pdf')}
            target="_blank"
            rel="noreferrer"
          >
            Click here for our Privacy Policy
          </a>
        </div>

        <h3 className="pages-content-layout__subtitle">WHAT ARE COOKIES?</h3>

        <p className="pages-content-layout__para">
          We use cookies, as do most website providers, to help maintain website functionality
          and to manage your preferences. A cookie is a small text file that a website saves
          on your web browser or mobile browser when you visit a website. It enables the website
          to remember your actions and preferences (such as login, language selection, font size
          and other display preferences) over a period of time, so you do not have to keep
          re-entering them whenever you come back to the site or browse from one page to another.
        </p>
        <p className="pages-content-layout__para">
          Cookies are a widely used form of technology and most, if not all, websites will set
          cookies. Cookies may be used for various purposes, including:
        </p>

        <ul className="pages-content-layout__bullets">
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            User preferences, including language
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            Authentication: to identify the user once logged in, for the duration of a session
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            Analytics: to provide information about website use and user profile
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            Targeting adverts
          </li>
        </ul>

        <h3 className="pages-content-layout__subtitle">HOW DO WE USE COOKIES?</h3>

        <p className="pages-content-layout__para">
          We may use both “session” cookies and “persistent” cookies on the website. We
          will use the session cookies to keep track of you whilst you navigate the website.
          We will use the persistent cookies to enable our website to recognise you when you visit.
        </p>
        <p className="pages-content-layout__para">
          Session cookies will be deleted from your computer when you close your browser. Persistent
          cookies will remain stored on your computer until deleted, or until they reach a specified
          expiry date.
        </p>
        <p className="pages-content-layout__para">
          We also use cookies to remember your device so we can deliver more relevant advertising
          to you. This means that the cookie remembers you have visited our site and will show you
          advertising which is relevant to you.
        </p>
        <p className="pages-content-layout__para">
          We use a mix of cookies. Some of these are set by our website, to manage your
          preferences. We also use third party cookies. This includes Google Analytics,
          who gather all of your information on an anonymised basis. This allows us to
          make sure our website is the best it can be.
        </p>
        <p className="pages-content-layout__para">
          Most third party cookies are on an anonymised basis, which means these organisations
          are not collecting any information that can be used to identify you.

        </p>
        <p className="pages-content-layout__para">
          We also integrate with social media providers such as Facebook, to help you to
          connect your account to our website and also to display advertising content which
          is relevant to you. These cookies use your personal data to deliver advertising
          which is relevant to your interests.
        </p>
        <p className="pages-content-layout__para">
          Any third party cookies are not controlled by this website for more information on
          these cookies we advise you to check the provider’s own Cookies Policy. Please see
          the overview below for a full list of cookies used on our website and the provider
          of each cookie.
        </p>
        <p className="pages-content-layout__para">
          Below we have provided an overview of our cookie use which you can review to decide
          how best to manage your online privacy.
        </p>

        <ul className="pages-content-layout__bullets">
          <li className="pages-content-layout__para">
            <strong>Necessary Cookies </strong>
            – these are always enabled as they are required for basic website maintenance. We
            cannot stop these from being set on your browser, but you can manage your preferences
            through your browser itself.
            <img src={necessaryCookies} alt="Necessary Cookies" />
          </li>
          <li className="pages-content-layout__para">
            <strong>Statistic Cookies </strong>
            – These cookies allow us to improve the site’s functionality by analysing usage
            of our website on an anonymised basis. In some cases these cookies improve the
            speed with which we can process your request or allow us to remember site preferences
            you have selected. De-selecting these cookies may result in poorly-tailored
            recommendations and slow site performance.
            <img src={statisticCookies} alt="Statistic Cookies" />
          </li>
          <li className="pages-content-layout__para">
            <strong>Social Media and Advertising Cookies </strong>
            – Social media cookies offer the possibility to connect you to your social networks
            and share content from our website through social media. Advertising cookies (of third
            parties) collect information to help better tailor advertising to your interests,
            both within and beyond our websites. This means the cookie will allow you to show
            you adverts relating to your interests. De-selecting these cookies may result in
            seeing advertisements that are not as relevant to you or you not being able to link
            effectively with Facebook, Twitter, or other social networks and/or not allowing
            you to share content on social media.
            <img src={socialCookies} alt="Social Cookies" />
          </li>
        </ul>

        <p className="pages-content-layout__para">
          You can
          {' '}
          <strong>
            control and/or delete
          </strong>
          {' '}
          cookies as you wish – for details, see
          {' '}
          <a
            href="https://www.aboutcookies.org/"
            target="_blank"
            rel="noreferrer"
          >
            www.aboutcookies.org.
          </a>
          {' '}
          You can delete all cookies that are already on your computer and you can set most
          browsers to prevent them from being placed. If you do this, however, you may have
          to manually adjust some preferences every time you visit a site and some services
          and functionalities may not work.
        </p>
        <p className="pages-content-layout__para">
          You can easily accept or reject the cookies on this site by using the options
          available on our cookie banner. Please note, all of your data is handled in
          accordance with our privacy policy and you have data rights over how we handle
          your data.
        </p>

        <h3 className="pages-content-layout__subtitle">POLICY AMENDMENTS</h3>

        <p className="pages-content-layout__para">
          We may update this policy from time-to-time by posting a new version on this page.
        </p>
        <p className="pages-content-layout__para">
          <strong>
            You should check this page occasionally to ensure you are happy with any changes.
          </strong>
        </p>

        <h3 className="pages-content-layout__subtitle">CONTACT US</h3>
        <p className="pages-content-layout__para">
          If you have any questions about this policy or our treatment of your personal
          data, please contact us:
        </p>

        <ul className="pages-content-layout__bullets">
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            by email at:
            {' '}
            <a href="mailto: dataprotection@theclaimsguys.co.uk">dataprotection@theclaimsguys.co.uk</a>
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            by post to: The Claims Guys Limited, Hamilton House, Church Street,
            Altrincham, Cheshire, WA14 4DR
          </li>
        </ul>
      </div>
    </section>
  );
}

PrivacyContentLayout.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default PrivacyContentLayout;
export { PrivacyContentLayout };
