import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import '../pages-content-layout.scss';

function TermsContentLayout({ location }) {
  return (
    <section className="pages-content-layout-section" id="pages-content-layout">
      <div className="container pages-content-layout" id="pages-content-layout">

        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          <strong>
            THESE TERMS OF USE GOVERN YOUR USE OF OUR WEBSITE; BY USING OUR WEBSITE, YOU
            ACCEPT THESE TERMS OF USE IN FULL. IF YOU DISAGREE WITH ANY PART OF THESE
            TERMS OF USE, YOU MUST NOT ACCESS OR USE OUR WEBSITE.
          </strong>
        </p>

        <h3 className="pages-content-layout__subtitle">1. Intellectual Property Rights</h3>

        <p className="pages-content-layout__para">
          Unless otherwise stated, we are the owner or licensee of all intellectual
          property rights in the website and material on the website.
        </p>
        <p className="pages-content-layout__para">
          You may view, download and print pages from the website for your own personal
          use, subject to the restrictions set out below and elsewhere in these terms of use.
        </p>
        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          <strong>
            You must not:
          </strong>
        </p>

        <ul className="pages-content-layout__bullets">
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            republish material from this website (including republication on another website);
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            sell, rent or sub-license material from the website;
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            reproduce, duplicate, copy or otherwise exploit material on our website for a
            commercial purpose;
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            edit or otherwise modify any material on the website; or
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            redistribute material from this website except for content specifically and
            expressly made available for redistribution such as our claims forms.
          </li>
        </ul>

        <h3 className="pages-content-layout__subtitle">2. Acceptable use</h3>

        <p className="pages-content-layout__para">
          You must not use our website in any way that causes, or may cause, damage
          to the website or impairment of the availability or accessibility of the
          website; or in any way which is unlawful, illegal, fraudulent or harmful,
          or in connection with any unlawful, illegal, fraudulent or harmful purpose
          or activity.
        </p>
        <p className="pages-content-layout__para">
          You must not use our website to copy, store, host, transmit, send, use,
          publish or distribute any material which consists of (or is linked to)
          any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit
          or other malicious computer software.
        </p>
        <p className="pages-content-layout__para">
          You must not conduct any systematic or automated data collection activities
          (including without limitation scraping, data mining, data extraction and
          data harvesting) on or in relation to our website without our express written consent.
        </p>
        <p className="pages-content-layout__para">
          You must not use our website for any purposes related to marketing without
          our express written consent.
        </p>

        <h3 className="pages-content-layout__subtitle">3. Limited warranties</h3>

        <p className="pages-content-layout__para">
          Whilst we endeavour to ensure that the information on this website is correct,
          we do not warrant its completeness or accuracy; nor do we commit to ensuring that
          the website remains available or that the material on the website is kept up-to-date.
        </p>
        <p className="pages-content-layout__para">
          To the maximum extent permitted by applicable law we exclude all representations,
          warranties and conditions relating to this website and the use of this website (including,
          without limitation, any warranties implied by law of satisfactory quality, fitness for
          purpose and/or the use of reasonable care and skill).
        </p>

        <h3 className="pages-content-layout__subtitle">4. Limitations of liabilities</h3>

        <p className="pages-content-layout__para">
          Nothing in these terms of use (or elsewhere on our website) will exclude or limit
          our liability for fraud, for death or personal injury caused by our negligence, or
          for any other liability which cannot be excluded or limited under applicable law.
        </p>
        <p className="pages-content-layout__para">
          Subject to this, our liability to you in relation to the use of our website or
          under or in connection with these terms of use, whether in contract, tort
          (including negligence) or otherwise, will be limited as follows:
        </p>

        <ul className="pages-content-layout__bullets">
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            to the extent that the website and the information and services on the website
            are provided free-of-charge, we will not be liable for any loss or damage of any nature;
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            we will not be liable for any consequential, indirect or special loss or damage;
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            we will not be liable for any loss of profit, income, revenue, anticipated
            savings, contracts, business, goodwill, reputation, data, or information;
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            we will not be liable for any loss or damage arising out of any event or events beyond
            our reasonable control;
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            our maximum liability in relation to any event or series of related events will be
            limited to £5,000
          </li>
        </ul>

        <h3 className="pages-content-layout__subtitle">5. Indemnity</h3>

        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          You hereby indemnify us and undertake to keep us indemnified against any losses,
          damages, costs, liabilities and expenses (including without limitation legal expenses
          and any amounts paid by us to a third party in settlement of a claim or dispute on
          the advice of our legal advisers) incurred or suffered by us arising out of any breach
          by you of any provision of these terms of use, or arising out of any claim that
          you have breached any provision of these terms of use.
        </p>

        <h3 className="pages-content-layout__subtitle">6. Breaches of these terms of use</h3>

        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          Without prejudice to our other rights under these terms of use, if you breach
          these terms of use in any way, we may take such action as we deem appropriate
          to deal with the breach, including suspending your access to the website,
          prohibiting you from accessing the website, blocking computers using your IP
          address from accessing the website, contacting your internet service provider
          to request that they block your access to the website and/or bringing court
          proceedings against you.
        </p>

        <h3 className="pages-content-layout__subtitle">7. Variation</h3>

        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          We may revise these terms of use from time-to-time. Revised terms of use
          will apply to the use of our website from the date of the publication of
          the revised terms of use on our website. Please check this page regularly
          to ensure you are familiar with the current version.
        </p>

        <h3 className="pages-content-layout__subtitle">8. Assignment</h3>

        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          We may transfer, sub-contract or otherwise deal with our rights and/or
          obligations under these terms of use without notifying you or obtaining your consent.
        </p>
        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          You may not transfer, sub-contract or otherwise deal with your rights and/or
          obligations under these terms of use.
        </p>

        <h3 className="pages-content-layout__subtitle">9. Severability</h3>

        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          If a provision of these terms of use is determined by any court or other competent
          authority to be unlawful and/or unenforceable, the other provisions will continue
          in effect. If any unlawful and/or unenforceable provision would be lawful or
          enforceable if part of it were deleted, that part will be deemed to be deleted,
          and the rest of the provision will continue in effect.
        </p>

        <h3 className="pages-content-layout__subtitle">10. Exclusion of third party rights</h3>

        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          These terms of use are for the benefit of you and us, and are not intended to
          benefit any third party or be enforceable by any third party. The exercise of
          our and your rights in relation to these terms of use is not subject to the
          consent of any third party.
        </p>

        <h3 className="pages-content-layout__subtitle">11. Entire agreement</h3>

        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          These terms of use together with our
          {' '}
          <Link to={`/privacy-policy/${location.search}`}>Privacy Policy</Link>
          , constitute the entire agreement between you and us in relation to your
          use of our website, and supersede all previous agreements in respect of your
          use of this website.
        </p>

        <h3 className="pages-content-layout__subtitle">12. Law and jurisdiction</h3>

        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          These terms of use will be governed by and construed in accordance with English
          law, and any disputes relating to these terms of use will be subject to the
          exclusive jurisdiction of the courts of England and Wales.
        </p>
        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          These terms of use are based on a precedent available at Website Contracts and
          Website Law.
        </p>

      </div>
    </section>
  );
}
TermsContentLayout.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default TermsContentLayout;
export { TermsContentLayout };
