import React from 'react';

import './contact-header.scss';

function ContactHeader() {
  return (
    <section className="contact-header-section">
      <div>
        <div className="contact-header">
          <div className="contact-header__hero">
            <div className="contact-header__heading">
              Information and Contact
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactHeader;
export { ContactHeader };
