import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@components';
import form from '@images/form.svg';
import like from '@images/like.svg';
import compensation from '@images/compensations.svg';

import './find-out.scss';

function FindOut() {
  return (
    <section className="container find-out-section">
      <div className="find-out" id="find-out">
        <div className="find-out__styled-title">
          <Typography component="h2">Work From Home Tax Refund Claims</Typography>
          <h3 className="find-out__subheading">
            Were you required to work from home for at least one day since April 2020?
            Find out if you can make a claim.
          </h3>
          <p className="find-out__para">
            We may contact you to see if our Partner, The Claims Guys Legal, could help
            you make a claim.
          </p>

          <div className="find-out__wrapper">
            <div className="find-out__box">
              <img src={form} alt="form complete icon" />
              <Typography component="h5">Complete online form</Typography>
              <p className="find-out__para">
                Simply provide some brief information about your claim and employment,
                then review and sign the claim documents provided.
              </p>
            </div>
            <div className="find-out__box">
              <img src={like} alt="like sign icon" />
              <Typography component="h5">Your claim is submitted</Typography>
              <p className="find-out__para">
                The team at The Claims Guys Legal will review the information you provide
                and submit your claim to HMRC. You’ll be kept updated as your claim progresses.
              </p>
            </div>
            <div className="find-out__box">
              <img src={compensation} alt="compensation sign icon" />
              <Typography component="h5">Receive your tax refund</Typography>
              <p className="find-out__para">
                You&apos;ll receive a tax refund of up to £125 for each tax year you
                worked from home.
              </p>
            </div>
          </div>
          {/* <div> */}
          {/*  <a className="find-out__cta-link" href={ctaLinks.WFH_LINK}> */}
          {/*    Start my claim &gt; */}
          {/*  </a> */}
          {/* </div> */}
        </div>
      </div>
    </section>
  );
}

FindOut.propTypes = {
  ctaLinks: PropTypes.shape({
    WFH_LINK: PropTypes.string.isRequired,
    UNIFORM_LINK: PropTypes.string.isRequired,
  }).isRequired,
};

export default FindOut;
export { FindOut };
