import React from 'react';
import { withPrefix } from 'gatsby';

import '../pages-content-layout.scss';

function ContactContentLayout() {
  return (
    <section className="pages-content-layout-section" id="pages-content-layout">
      <div className="container pages-content-layout" id="pages-content-layout">

        <h3 className="pages-content-layout__title">Service Information</h3>

        <p className="pages-content-layout__para">
          We submitted an application to the Financial Conduct Authority to cancel our
          claims management permissions in March 2021. To facilitate this application
          we ceased all regulated claims management activity on 29th June 2021. Our
          application with the Financial Conduct Authority was finalised on 31st July
          2022 and we are now no longer authorised by the Financial Conduct Authority,
          for regulated claims management activity.
        </p>
        <p className="pages-content-layout__para">
          There are some claims services that are not regulated by the Financial Conduct
          Authority, such as Tax Refund claims meaning we are still able to help our
          customers with these claim types.
        </p>
        <p className="pages-content-layout__para">
          If you need to get in touch with us about a previous claim, please use the
          contact information below.
        </p>

        <h3 className="pages-content-layout__title">Contact Us</h3>

        <p className="pages-content-layout__para">
          We are no longer operating any inbound or outbound telephone lines for PPI,
          Packaged Bank Account, Payday Loans, Investment claims, or Plevin PPI checks.
          We have a small team who continue to telephone customers who we believe may
          be eligible for a Tax Refund claim with one of our partners.  Please note,
          we do not provide any Tax Refund claims services directly to customers and
          this team are unable to assist you with any other enquiries.
        </p>

        <ul className="pages-content-layout__bullets">
          <li className="pages-content-layout__para">
            If you have a question about an offer your lender has made, or our fees,
            please get in touch at:
            {' '}
            <a href="mailto: payments@theclaimsguys.co.uk">payments@theclaimsguys.co.uk</a>
          </li>
          <li className="pages-content-layout__para">
            If you are enquiring about a communication you have received from us
            about Tax Refund claims, please email:
            {' '}
            <a href="mailto: assistance@theclaimsguys.co.uk">assistance@theclaimsguys.co.uk</a>
          </li>
          <li className="pages-content-layout__para">
            If you have submitted a Tax Refund claim with our partner The Claims
            Guys Legal, please email:
            {' '}
            <a href="mailto: tax@theclaimsguyslegal.com">tax@theclaimsguyslegal.com</a>
          </li>
          <li className="pages-content-layout__para">
            If you have an enquiry about how we are processing your data or want to
            opt-out of further communications from us, please email us at:
            {' '}
            <a href="mailto: dataprotection@theclaimsguys.co.uk">dataprotection@theclaimsguys.co.uk</a>
          </li>
        </ul>

        <h3 className="pages-content-layout__title">Complaints</h3>

        <div className="pages-content-layout__cta-link-wrapper">
          <a
            className="pages-content-layout__cta-link"
            href={withPrefix('/TCG_Complaint-Procedure_ENC_023_01_22.pdf')}
            target="_blank"
            rel="noreferrer"
          >
            Click here for our Complaint Procedure
          </a>
        </div>

        <h3 className="pages-content-layout__subtitle">Complaints by post</h3>

        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          <strong>
            WHEN WRITING TO COMPLAIN ABOUT OUR SERVICES, PLEASE INCLUDE THE FOLLOWING
            INFORMATION TO HELP EXPLAIN THE PROBLEM AND FIND A SOLUTION.
          </strong>
        </p>
        <ul className="pages-content-layout__bullets">
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            Write to us at: The Claims Guys, Hamilton House, Church Street, Altrincham,
            Cheshire, WA14 4DR.
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            Tell us about your complaint in as much detail as possible.
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            Let us know the best way to get in touch with you to discuss your complaint.
          </li>
        </ul>

        <h3 className="pages-content-layout__subtitle">Complaints by email</h3>

        <p className="pages-content-layout__para pages-content-layout__para--bolder">
          <strong>
            WHEN EMAILING TO COMPLAIN ABOUT OUR SERVICES, PLEASE INCLUDE THE FOLLOWING
            INFORMATION TO HELP EXPLAIN THE PROBLEM AND FIND A SOLUTION.
          </strong>
        </p>
        <ul className="pages-content-layout__bullets">
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            Email us at:
            {' '}
            <a href="mailto: complaints@theclaimsguys.co.uk">complaints@theclaimsguys.co.uk</a>
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            Tell us about your complaint in as much detail as possible.
            {' '}
          </li>
          <li className="pages-content-layout__para pages-content-layout__para--no-margin">
            Let us know the best way to get in touch with you to discuss your complaint.
          </li>
        </ul>
      </div>
    </section>
  );
}

export default ContactContentLayout;
export { ContactContentLayout };
