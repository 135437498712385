import React from 'react';

import './content-header.scss';
import PropTypes from 'prop-types';

function ContentHeader({ title }) {
  return (
    <section className="content-header-section">
      <div>
        <div className="content-header">
          <div className="content-header__hero">
            <div className="content-header__heading">
              {title}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ContentHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ContentHeader;
export { ContentHeader };
