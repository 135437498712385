import React from 'react';
import PropTypes from 'prop-types';

import './greetings.scss';

function Greeting() {
  return (
    <section className="greeting-section">
      <div>
        <div className="greeting">
          <div className="greeting__hero">
            <div className="greeting__box-container">

              {/* <div className="greeting__box"> */}
              {/*  <h4 className="greeting__box-title"> */}
              {/*    Claim for working from home! */}
              {/*  </h4> */}

              {/*  <p className="greeting__box-para"> */}
              {/*    If you were required to work from home, even for one day, */}
              {/*    since April 2020 our partner, The Claims Guys Legal, */}
              {/*    could help you claim a tax refund worth hundreds! */}
              {/*  </p> */}

              {/*  <a href={ctaLinks.WFH_LINK} className="greeting__box-link-wrapper"> */}
              {/*    <span className="greeting__box-link"> */}
              {/*      Start my claim */}
              {/*    </span> */}
              {/*  </a> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Greeting.propTypes = {
  ctaLinks: PropTypes.shape({
    WFH_LINK: PropTypes.string.isRequired,
    UNIFORM_LINK: PropTypes.string.isRequired,
  }).isRequired,
};

export default Greeting;
export { Greeting };
