import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@components';
import workingFromHome from '@images/WFH-300x240.png';
import ppiSttlemenets from '@images/PPI-300x267.png';
import uniformTax from '@images/uniform-300x226.png';
import marriageTax from '@images/rings-300x235.png';
import employmentExp from '@images/Eployment-expenses-300x139.png';
import unionPros from '@images/proffesionalFees-300x268.png';

import './other-refund-claims.scss';

function OtherRefundClaims() {
  return (
    <section className="container other-refund-claims-section">
      <div className="other-refund-claims" id="other-refund-claims">
        <div className="other-refund-claims__styled-title">
          <Typography component="h2">Other Tax Refund Claims</Typography>
          {/* <h3 className="other-refund-claims__subheading"> */}
          {/*  Were you required to work from home for at least one day since April 2020? */}
          {/*  Find out if you can make a claim. */}
          {/* </h3> */}
          {/* <p className="other-refund-claims__para"> */}
          {/*  Our Partner, The Claims Guys Legal, could help you claim */}
          {/*  up to £125 a year in tax relief. */}
          {/* </p> */}

          <div className="other-refund-claims__wrapper">
            <div className="other-refund-claims__box">

              <img src={workingFromHome} alt="Working From Home" />

              <h2>Working From Home</h2>

              <p className="other-refund-claims__para">
                If you have worked from home since April 2020 due to COVID you may be eligible
                to claim a tax refund worth up to £125 per tax year.
              </p>
              <p className="other-refund-claims__para">
                The tax relief is to cover extra costs incurred when working from home such
                as higher heating and electricity bills.
              </p>
              {/* <p className="other-refund-claims__para"> */}
              {/*  Our partner, The Claims Guys Legal, can help you make a claim even if you */}
              {/*  have worked from home for just one day. */}
              {/* </p> */}

              {/* <a */}
              {/*  className="other-refund-claims__cta-link" */}
              {/*  href={ctaLinks.WFH_LINK} */}
              {/* > */}
              {/*  Start my claim &gt; */}
              {/* </a> */}
            </div>
            <div className="other-refund-claims__box">
              <img src={ppiSttlemenets} alt="PPI Settlements" />
              <h2>PPI Settlements</h2>
              <p className="other-refund-claims__para">
                If you have had a successful PPI claim in the last four years, you could
                claim back 100% of the tax your lender deducted from your PPI settlement.
              </p>
              <p className="other-refund-claims__para">
                If you were a customer of The Claims Guys we may have already been in touch
                with you about this to introduce you to our HMRC registered Tax Agent partner.
              </p>
            </div>
            <div className="other-refund-claims__box">
              <img src={uniformTax} alt="Uniform Allowances" />
              <h2>Uniform Allowances</h2>
              <p className="other-refund-claims__para">
                If you wear a uniform to work which you have to wash, repair or replace
                yourself, you may be able to claim a tax refund worth £100s going back four years.
              </p>
              <p className="other-refund-claims__para">
                The amount you are eligible to claim will depend on the industry you work in but
                you can claim for most types of uniform from a simple branded t-shirt to a
                full uniform, such as that worn by a pilot, police officer or nurse.
              </p>
              {/* <p className="other-refund-claims__para"> */}
              {/* Our partner, The Claims Guys Legal, who sometimes use */}
              {/* the brand My Money Back, */}
              {/*  can help you make a claim. */}
              {/* </p> */}

              {/* <a */}
              {/*  className="other-refund-claims__cta-link" */}
              {/*  href={ctaLinks.UNIFORM_LINK} */}
              {/* > */}
              {/*  Start my claim &gt; */}
              {/* </a> */}
            </div>
            <div className="other-refund-claims__box other-refund-claims__coming-soon-label">
              <img src={marriageTax} alt="Marriage Tax Allowance" />
              <h2>Marriage Tax Allowance</h2>
              <p className="other-refund-claims__para">
                If you’re married or in a civil partnership, you may be entitled to a tax
                refund worth £1000s due to Marriage Tax Allowance.
              </p>
              <p className="other-refund-claims__para">
                Marriage Tax Allowance allows you to transfer some of your Personal
                Allowance (the amount you can earn tax-free each tax year) to your spouse
                or civil partner if they earn more than you.
              </p>
              <p className="other-refund-claims__para">
                Your claim can be backdated for 4 years which means you can claim up to
                £1220 with a further future savings of £1000’s.
              </p>
            </div>
            <div className="other-refund-claims__box other-refund-claims__coming-soon-label">
              <img src={employmentExp} alt="compensation sign icon" />
              <h2>Employment Expenses</h2>
              <p className="other-refund-claims__para">
                If you are required to use your own vehicle for work, you
                may be able to claim Mileage Allowance based on the number of
                business miles you travel. This tax relief could be worth up to
                45p for every mile you have travelled in the last four years!
              </p>
              <p className="other-refund-claims__para">
                Additionally, if you pay for your own tools or equipment, then
                you’ll be eligible to claim back some of these costs.
              </p>
            </div>
            <div className="other-refund-claims__box other-refund-claims__coming-soon-label">
              <img src={unionPros} alt="compensation sign icon" />
              <h2>Union or Professional Fees</h2>
              <p className="other-refund-claims__para">
                If you have to pay union professional fees because of your job, or are
                in a profession where you must pay a subscription or a professional
                fee it is likely you can get tax relief on the annual cost of these
                fees. This claim can be backdated for up to four years meaning you
                could be due a refund worth £100s and should reduce your tax bill for
                future years too!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

OtherRefundClaims.propTypes = {
  ctaLinks: PropTypes.shape({
    WFH_LINK: PropTypes.string.isRequired,
    UNIFORM_LINK: PropTypes.string.isRequired,
  }).isRequired,
};

export default OtherRefundClaims;
export { OtherRefundClaims };
